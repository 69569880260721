import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { startNewDroplet, deleteDroplet } from '../api/apiUser';
import axios from 'axios';
import CardComponent from './CardComponent';
import Swal from 'sweetalert2';
import { getToken } from '../api/apiUser';
import NumericInput from 'react-numeric-input';
import '../css/CardNumberInput.css'

export default function PostCard({ selectedDeck, incrementDeckNum }) {
  const [error, setError] = useState(null);
  const [word, setWord] = useState(null);
  const [translateFrom, setTranslateFrom] = useState('');
  const [translateTo, setTranslateTo] = useState('');
  const [cards, setCards] = useState([]);
  const [generateImage, setGenerateImage] = useState(false); // New state for Generate Image
  const [cardIndex, setCardIndex] = useState(0);
  const [readyCards, setReadyCards] = useState([]);
  const [processingCards, setProcessingCards] = useState([]);
  const [insertedCards, setInsertedCards] = useState([]);
  const [viewMode, setViewMode] = useState('active');
  const [loading, setLoading] = useState(false);

  const commonLanguages = [
    { code: 'English', label: 'English' },
    { code: 'Spanish', label: 'Spanish' },
    { code: 'French', label: 'French' },
    { code: 'German', label: 'German' },
    { code: 'Italian', label: 'Italian' },
    { code: 'Chinese', label: 'Chinese' },
    { code: 'Japanese', label: 'Japanese' },
    { code: 'Russian', label: 'Russian' },
    { code: 'Arabic', label: 'Arabic' }
  ];


    const fetchReadyCards = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_ready_cards`, {
          headers: {
            'Authorization': `Bearer ${getToken()}`,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
          }
        });
        console.log('new ready cards', response);
        console.log('time offset', (new Date()).getTimezoneOffset())
        for (let card of response.data.cards) {
          card.original_non_english_word = card.non_english_word;
          card.original_english_word = card.english_word;
        }
        response.data.cards.sort((a, b) => new Date(b.date) - new Date(a.date));
        setReadyCards(response.data.cards.filter(card => !cards.map(c => c.id).includes(card.id)));
      } catch (error) {
        console.error(error);
      }
    }

    const fetchProcessingCards = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_processing_cards`, {
          headers: { 'Authorization': `Bearer ${getToken()}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0' 
                  }
        });
        console.log(response);
        for (let card of response.data.cards) {
          card.original_non_english_word = card.non_english_word;
          card.original_english_word = card.english_word;
        }
        response.data.cards.sort((a, b) => new Date(b.date) - new Date(a.date));
        setProcessingCards(response.data.cards.filter(card => !cards.map(c => c.id).includes(card.id)));
      } catch (error) {
        console.error(error);
      }
    }

    const fetchInsertedCards = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_inserted_cards`, {
          headers: { 'Authorization': `Bearer ${getToken()}`, 
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                  }
        });
        console.log(response);
        response.data.cards.sort((a, b) => new Date(b.date) - new Date(a.date));
        setInsertedCards(response.data.cards);
      } catch (error) {
        console.error(error);
      }
    }


  const updateCards = (obj, index, _view) => {
    const internalSetter = (prevCards) => {
      const updatedCards = [...prevCards];
  
      if (index < 0) {
        updatedCards.push(obj);
        return updatedCards;
      }
  
      if (index >= 0 && index < updatedCards.length) {
        updatedCards[index] = {
          ...updatedCards[index], // Preserve existing properties
          ...obj, // Overwrite with new properties
        };
        return updatedCards;
      }
  
      console.warn(`Index ${index} is out of bounds. No changes made.`);
      return updatedCards;
    }
    if (_view === 'active') {
      setCards(internalSetter);
    }
    else if (_view === 'processing') { 
      setProcessingCards(internalSetter);
    }
    else if (_view === 'ready') {
      setReadyCards(internalSetter);
    }
    else if (_view === 'inserted') {
      setInsertedCards(internalSetter);
    }
    // Return the operated index
    return index < 0 ? cards.length : index;
  };
  
  const cullInvisible = () => {
    if (viewMode === 'active') {
      setCards((prevCards) => {
        const visibleCards = prevCards.filter(card => card.visible == true);
        return visibleCards;
      });
    }
    else if (viewMode === 'processing') {
      setProcessingCards((prevCards) => {
        const visibleCards = prevCards.filter(card => card.visible == true);
        return visibleCards;
      });
    }
    else if (viewMode === 'ready') {
      setReadyCards((prevCards) => {
        const visibleCards = prevCards.filter(card => card.visible == true);
        return visibleCards;
      });
    }
  };

  const postCard = async () => {
    if (selectedDeck?.length == null) {
      Swal.fire({
        icon: 'error',
        title: 'Deck not selected!',
        text: 'Select a deck to upload to.',
      });
      return;
    }
    console.log(translateFrom, translateTo);
    if (translateFrom == '' || translateTo == '') {
      Swal.fire({
        icon: 'error',
        title: 'Language not selected!',
        text: 'Make sure you have selected your languages.',
      });
      return;
    }
    setError(null);
    Swal.fire({
      icon: 'success',
      title: 'Generating card...',
      text: 'AI is making your card...',
    });
    const index = updateCards({
      english_word: word,
      loading: true,
      visible: true,
    }, -1, 'active'); //make a new card with -1 and get the index
    try {
      const deckName = selectedDeck;
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/post_card`, {
        word,
        translateFrom,
        translateTo,
        deckName,
        generateImage, 
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`,
        }
      });
      console.log('response:', response);

      updateCards({
        deck_name: response.data.data.deck_name,
        english_word: response.data.data.english_word,
        non_english_word: response.data.data.non_english_word,
        english_sentences: response.data.data.english_sentences,
        non_english_sentences: response.data.data.non_english_sentences,
        img_url: response.data.data.img_url,
        original_english_word: response.data.data.english_word,
        original_non_english_word: response.data.data.non_english_word,
        id: response.data.data.id,
        loading: false,
        visible: true,
      }, index, 'active'); //update the loading card

    } catch (error) {
      setError('Failed: ' + error.message);
      updateCards({
        english_word: `${word}, failed`,
        loading: true,
        visible: true,
      }, index, 'active');
    } finally {
      console.log('Success')
    }
  };

  const uploadCard = async (index) => {
    if (selectedDeck?.length == null) {
      Swal.fire({
        icon: 'error',
        title: 'Deck not selected!',
        text: 'Select a deck to upload to.',
      });
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload_card`, getCardByIndex(index), {
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`,
        }
      });
      Swal.fire({
        icon: 'success',
        title: 'Card Uploaded!',
        text: 'The card was successfully uploaded',
      });
      console.log(response);
      incrementDeckNum();
      updateCards({visible:false}, index, viewMode);
      cullInvisible();

      // Log the updated state after the update
      setTimeout(() => {
        console.log(cards,index); // This ensures the log reflects the updated state
      }, 0);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Card Upload Error',
        text: `Failed to upload card: ${error.message}`,
      });
      console.log(error);
    }
  };

  const getIdByIndex = (index) => { 
    if (viewMode === 'active') {
      return cards[index].id;
    }
    else if (viewMode === 'processing') {
      return processingCards[index].id;
    }  else if (viewMode === 'ready') {
      return readyCards[index].id;
    }
  }

  const getCardByIndex = (index) => {
    if (viewMode === 'active') {
      return cards[index];
    }
    else if (viewMode === 'processing') {
      return processingCards[index];
    }  else if (viewMode === 'ready') {
      return readyCards[index];
    } else if (viewMode === 'inserted') {
      return insertedCards[index];
    }  
  }

  const rejectCard = async (index) => {
    console.log('reject');
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/delete_card`, {id: getIdByIndex(index)}, {
          headers: {
            'Authorization': `Bearer ${getToken()}`,
          }
        });
      } catch (error) {
        console.error(error);
        return;
      }

    Swal.fire({
      icon: 'success',
      title: 'Card Rejected',
      text: 'You rejected the card. Generate another.',
    });
    updateCards({visible:false}, index, viewMode);
    cullInvisible();
    setTimeout(() => {
      console.log(cards, index); // This ensures the log reflects the updated state
    }, 0);
  };

  const editCard = (newVal, field, index) => {
    if (index < 0 || index >= getCurrentLength()) {
      console.error(`Invalid index: ${index}`);
      return;
    }
    const card = getCardByIndex(index);
    if (!card) {
      console.error(`Card at index ${index} is undefined`);
      return;
    }
    let editedCards = [...cards];
    editedCards[index] = {
      ...card,
      [field]: newVal,
    };
    updateCards(editedCards[index], index, viewMode);
  };

  useEffect(() => {
    async function fetchCards() {
      console.log('viewMode', viewMode);
      if (viewMode === 'processing') {
        setLoading(true);
        fetchProcessingCards().then(() => {
          setLoading(false);
        }
        );
      } else if (viewMode === 'ready') {
        setLoading(true);

        fetchReadyCards().then(() => {
          setLoading(false);
        }
        );
      }
      else if (viewMode === 'inserted') {
        fetchInsertedCards(); 
      }
    }
    fetchCards();
  }, [viewMode]);

  const handleModeChange = (e) => {
    setCardIndex(0);
    setViewMode(e.target.value);
  };

  const getCorrectCards = () => {
    if (viewMode === 'active') {
      return (
        <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center', // Center vertically (optional)
          gap: '16px', // Space between cards
        }}
      >
        {cards.map((card, index) => (
          card.visible && index == cardIndex && (
            <div key={index} style={{ flex: '1 0 300px', maxWidth: '300px' }}>
              <CardComponent
                card={card}
                index={index}
                uploadFunc={uploadCard}
                rejectFunc={rejectCard}
                editFunc={editCard}
                showButtons={true}
              />
            </div>
          )
        ))}
      </div>
      )
    } 
  else if (loading === true) {
    return (
      <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center', // Center vertically (optional)
        gap: '16px', // Space between cards
      }}
    > 
    {`Loading...`}
    </div>
    )}
  else if (viewMode === 'processing') {
    return (
      <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center', // Center vertically (optional)
        gap: '16px', // Space between cards
      }}
    >
      {processingCards && processingCards.map((card, index) => card.visible && index == cardIndex && 
      (
        <div key={index} style={{ flex: '1 0 300px', maxWidth: '300px' }}>
          <CardComponent
            card={card}
            index={index}
            uploadFunc={uploadCard}
            rejectFunc={rejectCard}
            editFunc={editCard}
            showButtons={true}
          />
        </div>
      ))}
    </div>
    )} 
    else if (viewMode === 'ready') {
      console.log('readyCards',readyCards);
      return (
        <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center', // Center vertically (optional)
          gap: '16px', // Space between cards
        }}
      >
        {readyCards && readyCards.map((card, index) => (
           card.visible && index == cardIndex && (
            <div key={index} style={{ flex: '1 0 300px', maxWidth: '300px' }}>
              <CardComponent
                card={card}
                index={index}
                uploadFunc={uploadCard}
                rejectFunc={rejectCard}
                editFunc={editCard}
                showButtons={true}
              />
            </div>
          )
        ))}
      </div>
      )
    }
    else if (viewMode === 'inserted') {
      return (
        <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center', // Center vertically (optional)
          gap: '16px', // Space between cards
        }}
      >
        {insertedCards && insertedCards.map((card, index) => (
           card.visible && index == cardIndex && (
            <div key={index} style={{ flex: '1 0 300px', maxWidth: '300px' }}>
              <CardComponent
                card={card}
                index={index}
                uploadFunc={uploadCard}
                rejectFunc={rejectCard}
                editFunc={editCard}
                showButtons={false}
              />
            </div>
          )
        ))}
      </div>
      )
    }
  }

  const getCurrentLength = () => {
    if (viewMode === 'active') {
      return cards.length;
    } else if (viewMode === 'processing') {
      return processingCards.length;
    } else if (viewMode === 'ready') {
      return readyCards.length;
    } else if (viewMode === 'inserted') {
      return insertedCards.length;
    }
  }
  
  
  return (
    <div>
      <div>
        <div style = {{display: "flex", flexDirection: "row"}}>
          <Form.Group className="mb-3" controlId="formTranslateFrom">
            <Form.Select
              value={translateFrom}
              onChange={(e) => setTranslateFrom(e.target.value)}
              style={{ width: "150px", justifyContent: 'center', fontSize: "14px" }}
            >
              <option value="">Translate from</option>
              {commonLanguages.map((language) => (
                <option key={language.code} value={language.code}>
                  {language.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formTranslateTo">
            <Form.Select
              value={translateTo}
              onChange={(e) => setTranslateTo(e.target.value)}
              style={{ width: "150px", justifyContent: 'center', fontSize: "14px" }}
            >
              <option value="">Translate to</option>
              {commonLanguages.map((language) => (
                <option key={language.code} value={language.code}>
                  {language.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
      {/* Input box for the word */}
      <Form.Group className="mb-3" controlId="formWordInput">
          <Form.Control
            type="text"
            placeholder="Enter word"
            value={word}
            onChange={(e) => setWord(e.target.value)}
            style={{ width: "300px", justifyContent: 'center' }}
          />
        </Form.Group>
      </div>    
        <div style={{display: "flex", flexDirection: "row", gap: "10px"}}>
          <Button
            onClick={postCard}
            style={{ width: "150px" }}
            disabled={!word || !selectedDeck}
          >
            Post Card
          </Button>
          <Form.Group className="mb-3" controlId="formGenerateImage" style={{ margin: 0 }}>
            <Form.Check
              type="checkbox"
              label="Generate Image"
              checked={generateImage}
              onChange={(e) => setGenerateImage(e.target.checked)}
            />
          </Form.Group>
        </div>

        {error && <p className="text-danger mt-3">Error: {error}</p>}
        <div style={{ marginBottom: '20px' }}>
          <Form.Group controlId="viewModeChoiceBox">
            <Form.Label>View Mode</Form.Label>
            <Form.Select
              value={viewMode}
              onChange={handleModeChange}
              style={{ width: '200px', fontSize: '14px' }}
            >
              <option value="active">Active</option>
              <option value="processing">Processing</option>
              <option value="ready">Ready</option>
              <option value="inserted">Inserted</option>
            </Form.Select>
          </Form.Group>
        </div>
        {getCorrectCards()}
        <div style={{paddingTop: "60px"}}>
          <NumericInput
            min={1}
            max={getCurrentLength() > 0 ? getCurrentLength() : 1}
            value={cardIndex + 1}            // display as 1-indexed
            onChange={(value) => setCardIndex(value - 1)}
            mobile={true}                 // use the custom spinner, not the native input
            className="numeric-input-wrapper"
            inputClassName="numeric-input-field"
            btnUpClassName="numeric-input-btn-up"
            btnDownClassName="numeric-input-btn-down"
          />
        </div>
    </div>

  );
}
